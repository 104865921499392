<template>
  <!-- <v-menu :close-on-content-click="true">
    <template v-slot:activator="{ on: activatorOn }">
      <slot name="activator" :current="currentPrinter" :on="activatorOn">
        <v-btn
          class="printer-select__activator"
          text
          color="accent"
          v-on="activatorOn"
          @click="refreshItems"
          v-text="currentPrinter.displayName || 'Select a printer'"
        />
      </slot>
    </template>
    <v-list :key="renderKey">
      <template v-if="items.length === 0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>No printers available</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-for="printer in items" v-else>
        <v-list-item
          :key="JSON.stringify(printer)"
          :color="(isSelected(printer) && `primary`) || ``"
          @click="setPrinter(printer)"
        >
          <v-list-item-content>
            <v-list-item-title v-text="printer.displayName" />
          </v-list-item-content>
          <v-list-item-action v-if="isSelected(printer)">
            <v-icon color="primary" v-text="`$vuetify.icons.check`" />
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
  </v-menu> -->
  <v-select
    v-if="items.length > 0"
    v-model="selectedPos"
    :items="items"
    return-object
    item-text="displayName"
    item-value="name"
    :menu-props="{ offsetY: true, bottom: true }"
  />
  <div v-else class="ma-2 d-flex align-start flex-column">
    <span class="text-body-1 my-2">{{
      $t("buy.setup.posSelect.noPrinters")
    }}</span>
    <v-btn text color="primary" @click="() => refreshItems(true)">
      <v-icon left>$refresh</v-icon>
      {{ $t("refresh") }}</v-btn
    >
  </div>
</template>

<script>
import { hasSnackbarAccess } from "@/mixins/ui";

export default {
  name: "PrinterSelect",
  mixins: [hasSnackbarAccess],
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    renderKey: 0,
    items: [],
    dialog: false,
  }),
  computed: {
    currentPrinter() {
      return this.$raiPos.printer();
    },
    selectedPos: {
      get() {
        return this.$raiPos.printer();
      },
      set(v) {
        this.setPrinter(v);
      },
    },
  },
  created() {
    this.refreshItems();
  },
  methods: {
    refreshItems(showUnavailableSnackbar = false) {
      this.items =
        (this.$raiPos &&
          this.$raiPos.availablePosDevices &&
          this.$raiPos.availablePosDevices()) ||
        [];

      if (showUnavailableSnackbar && this.items.length === 0) {
        this.showSnackbar({
          text: this.$t("buy.setup.posSelect.noPrinters"),
        });
      }
    },
    setPrinter(printer) {
      this.$raiPos.setPrinter(printer);
      this.$emit("input", false);
      this.showSnackbar({
        text: this.$t("buy.setup.posSelect.successMessage", {
          printerName: this.$raiPos.printerDisplayName(),
        }),
      });
      this.renderKey++;
    },
    isSelected(printer) {
      return this.$raiPos.printerName() === printer.name;
    },
  },
};
</script>
