<template class="ui-l4">
  <v-card>
    <!-- UI settings -->
    <v-list three-line subheader>
      <v-subheader>Interface settings</v-subheader>

      <!-- TEAM MODE -->
      <v-list-item>
        <v-list-item-action>
          <v-checkbox v-model="teamMode" readonly disabled></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Team mode</v-list-item-title>
          <v-list-item-subtitle
            >Multi-user mode designed for use on shared
            devices.</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <!-- SCREEN TIMEOUT -->
      <v-list-item :ripple="false">
        <v-list-item-action></v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Screen timeout</v-list-item-title>
          <v-list-item-subtitle
            >Lock the screen after {{ idleSeconds }} seconds of
            inactivity.</v-list-item-subtitle
          >
          <v-slider
            v-model="idleSeconds"
            step="30"
            ticks
            min="0"
            :max="max"
          ></v-slider>
        </v-list-item-content>
      </v-list-item>

      <!-- <v-list-item :ripple="false">
            <v-list-item-action>
              <v-checkbox v-model="posProxy"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Print to POS</v-list-item-title>
              <v-list-item-subtitle>
                Enable printing to a remote point-of-sale for check-in on
                handheld devices.
              </v-list-item-subtitle>
              <v-list-item-subtitle class="font-italic caption">
                * Limited to handheld devices designed for exclusive use by
                ResaleAI.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>-->

      <!-- PRINTER SELECT -->
      <v-list-item v-if="shouldShowPrinterSelect" :ripple="false">
        <v-list-item-action />
        <v-list-item-content>
          <v-list-item-title>{{
            $t("buy.setup.posSelect.title")
          }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ $t("buy.setup.posSelect.extension") }}
          </v-list-item-subtitle>
          <PrinterSelect />
        </v-list-item-content>
      </v-list-item>
      <v-list-item :ripple="false">
        <v-list-item-action> </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Theme</v-list-item-title>
          <v-select
            v-model="theme"
            :items="['light', 'dark', 'system']"
            :menu-props="{ offsetY: true, bottom: true }"
          ></v-select>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list three-line subheader>
      <v-subheader>Preferences</v-subheader>
      <v-list-item :ripple="false">
        <v-list-item-action>
          <v-checkbox v-model="buyFlow"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Beta buy flow</v-list-item-title>
          <v-list-item-subtitle>
            An improved version of the buy sign in process that will become the
            default.
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <!-- <v-list-item :ripple="false">
        <v-list-item-action>
          <v-checkbox v-model="freshdesk"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Freshdesk</v-list-item-title>
          <v-list-item-subtitle
            >Use Freshdesk for chat and support.</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item> -->
    </v-list>
    <v-divider></v-divider>
    <!-- EXPERIMENTAL -->
    <v-list three-line subheader>
      <v-subheader>Experimental</v-subheader>
      <v-list-item :ripple="false">
        <v-list-item-action>
          <v-checkbox v-model="keepAlive"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Greedy mode</v-list-item-title>
          <v-list-item-subtitle
            >Make the app faster by allowing it to use more system
            resources.</v-list-item-subtitle
          >
          <v-list-item-subtitle class="font-italic text-caption">
            CAUTION: Enabling this setting may reduce overall system stability.
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="versionCount">
        <v-list-item-content>
          <v-list-item-title>Version {{ appVersion }}</v-list-item-title>
          <v-list-item-subtitle v-if="versionTaps > 5">{{
            versionTaps
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list v-if="devMode" three-line subheader>
      <v-subheader>DEVELOPMENT ONLY</v-subheader>
      <v-list-item :ripple="false">
        <v-list-item-action>
          <v-checkbox v-model="fullSettings"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Display full settings</v-list-item-title>
          <v-list-item-subtitle
            >View full settings in development mode.</v-list-item-subtitle
          > </v-list-item-content
        >subheader
      </v-list-item>
      <v-list-item href="https://next.resaleai.com/">
        <v-list-item-content>
          <v-list-item-title>Use unstable version</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<style>
.printer-select__activator {
  flex: 0 1 auto;
}
</style>

<script>
import { mapState } from "vuex";
import PrinterSelect from "@/components/pos/PrinterSelect";

export default {
  name: "DeviceView",
  components: {
    PrinterSelect,
  },
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION,
      versionTaps: 0,
    };
  },
  computed: {
    ...mapState("ui", ["dark"]),
    shouldShowPrinterSelect() {
      return !!this.$raiPos?.isPosProxy;
    },
    buyFlow: {
      get() {
        return this.$store.state.ui.newBuyFlow;
      },
      set(v) {
        this.$store.commit("ui/toggleNewBuyFlow");
      },
    },
    theme: {
      get() {
        return this.$store.state.ui.theme;
      },
      set(v) {
        if (v === "system") {
          // set to system
          this.$vuetify.theme.dark = this.$store.state.ui.systemDark;
        } else {
          this.$vuetify.theme.dark = v === "dark";
        }
        this.$store.commit("ui/setTheme", v);
      },
    },
    freshdesk: {
      get() {
        return this.$store.state.ui.useFreshdesk;
      },
      set(v) {
        this.$store.commit("ui/toggleFreshdesk");
      },
    },
    altSchedule: {
      get() {
        return this.$store.state.ui.scheduleDrawer;
      },
      set(v) {
        this.$store.commit("ui/toggleScheduleDrawer");
      },
    },
    posProxy: {
      get() {
        return this.$store.state.ui.posProxy;
      },
      set(v) {
        this.$store.commit("ui/togglePosProxy");
      },
    },
    teamMode: {
      get() {
        return this.$store.state.auth.teamMode;
      },
    },
    fullSettings: {
      get() {
        return this.$store.state.ui.fullSettings;
      },
      set(v) {
        this.$store.commit("ui/toggleFullSettings");
      },
    },
    idleSeconds: {
      get() {
        return this.$store.state.ui.idle;
      },
      set(v) {
        this.$store.commit("ui/setIdle", v);
      },
    },
    keepAlive: {
      get() {
        return this.$store.state.ui.keepAlive;
      },
      set(v) {
        this.$store.commit("ui/toggleKeepAlive");
      },
    },
    devMode() {
      return process.env.NODE_ENV === "development" || this.versionTaps >= 10;
    },
    max() {
      return (this.devMode && 60 * 10) || 300;
    },
  },
  mounted() {
    this.versionTaps = 0;
  },
  methods: {
    toggleNewBuyFlow() {
      this.$store.commit("ui/toggleNewBuyFlow");
      this.$nextTick(() => {
        this.refreshNow();
      });
    },
    toggleSchedule() {
      this.$store.commit("ui/toggleScheduleDrawer");
    },
    // increase versionTap count
    // once count >= 10, enable devmode
    versionCount() {
      this.versionTaps += 1;
    },
  },
};
</script>
